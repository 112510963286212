<template>
  <div class="v_ap_receiving_gift_card_index">
    <detail-card
      :mobile="mobile"
    ></detail-card>
  </div>
</template>

<script>
import DetailCard from './Modules/DetailCard.vue'
import self_adaption from '@/utils/self_adaption'
import rem from '@/utils/self_adaption/rem'
export default {
  components: {
    DetailCard,
  },
  mounted(){
    this.resizeHandler()
    window.addEventListener('resize', ()=>{
      this.resizeHandler()
    })
  },
  beforeDestroy(){
    rem.remove()
    window.removeEventListener('resize', this.resizeHandler)
  },
  data(){
    return {
      mobile: false,
    }
  },
  methods: {
    resizeHandler(){
      const {mobile} = self_adaption.set({
        width: 750,
        font_size: 14,
      })
      this.mobile = mobile
    }
  }
}
</script>

<style lang="scss" scoped>
.v_ap_receiving_gift_card_index{
  background-color: #F5F6F7;
  min-height: 100%;
  width: 100%;
}
</style>
