var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_ap_receiving_gift_card_m_detail_card bbox d-flex flex-column align-center",
      class: { mobile: _vm.mobile },
    },
    [
      _c(
        "div",
        {
          staticClass: "detail_group bbox d-flex flex-column align-center bbox",
        },
        [
          _c(
            "div",
            { staticClass: "card_group bbox d-flex flex-column align-center" },
            [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.mobile,
                    expression: "!mobile",
                  },
                ],
                staticClass: "logo",
                attrs: { src: require("@/assets/logo.png"), alt: "" },
              }),
              _c(
                "div",
                { staticClass: "text_group" },
                [
                  _vm._l(_vm.text_list, function (item, index) {
                    return [
                      _c("div", { key: index, staticClass: "text" }, [
                        _vm._v(_vm._s(item)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
              _c("card-component", {
                staticClass: "card_component",
                attrs: { width: _vm.card_width, height: _vm.card_height },
              }),
              _c(
                "div",
                { staticClass: "hint_group" },
                [
                  _vm._l(_vm.hint_list.split("\n"), function (item, index) {
                    return [
                      _c("div", { key: index, staticClass: "hint" }, [
                        _vm._v(_vm._s(item)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("div", { staticClass: "button btn_dark" }, [_vm._v("立即领取")]),
        ]
      ),
      _c("about-us", {
        staticClass: "about_us",
        attrs: { mobile: _vm.mobile },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }