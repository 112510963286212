var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_ap_receiving_gift_card_index" },
    [_c("detail-card", { attrs: { mobile: _vm.mobile } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }