var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_ap_receiving_gift_card_m_about_us bbox d-flex align-center flex-column",
      class: { mobile: _vm.mobile },
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("关于妙行")]),
      _c(
        "div",
        {
          staticClass:
            "list_group d-flex align-center justify-center bbox flex-wrap",
        },
        [
          _vm._l(_vm.list, function (item, index) {
            return [
              _c(
                "div",
                { key: index, staticClass: "unit d-flex align-center bbox" },
                [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: require(`@/assets/images/personal_center/receiving_gift_card/aboutus${item.icon}.png`),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(item.text)),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }