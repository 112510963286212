<template>
  <div class="v_ap_receiving_gift_card_m_about_us bbox d-flex align-center flex-column"
    :class="{mobile}"
  >
    <div class="title">关于妙行</div>
    <div class="list_group d-flex align-center justify-center bbox flex-wrap">
      <template
        v-for="(item, index) in list"
      >
        <div class="unit d-flex align-center bbox"
          :key="index"
        >
          <img
            :src="require(`@/assets/images/personal_center/receiving_gift_card/aboutus${item.icon}.png`)"
            alt=""
            class="icon"
          >
          <div class="text">{{item.text}}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mobile:{
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      list: [
        {icon: 1, text: '基于沟通和微观任务控制'},
        {icon: 2, text: '产教贯通管教融合'},
        {icon: 3, text: '实践教学体系化内容'},
        {icon: 4, text: '能力画像'},
        {icon: 5, text: '管理行为大数据'},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.v_ap_receiving_gift_card_m_about_us{
  width: 100%;
  padding-top: 50px;
  .title{
    font-size: 16px;
    color: #002141;
    line-height: 23px;
    position: relative;
    z-index: 2;
    &::before,&::after{
      content: '';
      display: block;
      width: 100px;
      height: 1px;
      background-color: #D5D9DB;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
    &::before{
      left: -120px;
    }
    &::after{
      right: -120px;
    }
  }
  .list_group{
    font-size: 14px;
    color: #82929C;
    line-height: 20px;
    margin-top: 20px;
    .icon{
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .unit{
      padding-right: 20px;
    }
  }
  &.mobile{
    padding-top: 0.5rem;
    background: linear-gradient(180deg, #E5EDF6 0%, rgba(229, 237, 246, 0) 100%);
    .list_group{
      width: 100%;
      justify-content: start;
      padding: 0 0.3rem;
      font-size: 12px;
      .unit{
        width: 50%;
        flex-shrink: 0;
        margin-bottom: 0.2rem;
        padding-right: 0.2rem;
      }
      .icon{
        width: 0.6rem;
        height: 0.6rem;
        margin-right: 0.1rem;
      }

    }
  }
}
</style>
