/*
 * @Author       : Hugo
 * @Date         : 2022-04-22 15:15:35
 * @LastEditTime : 2022-04-22 15:18:09
 * @LastEditors  : Hugo
 * @FilePath     : /pmcs_sub/src/utils/modules/platform.js
 * @Description  : 
 * @^_^          : 
 */
class Platform{
  isMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    if(flag == null){
      return false
    }else{
      return true
    }
  }
}

const platform = new Platform()

export default platform