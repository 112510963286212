/*
 * @Author       : Hugo
 * @Date         : 2022-02-25 16:25:47
 * @LastEditTime : 2022-02-25 16:42:22
 * @LastEditors  : Hugo
 * @FilePath     : /pmcs_sub/src/utils/modules/rem.js
 * @Description  : 
 * @^_^          : 
 */
class Rem{
  /**
   * 设置自适应
   * @param {*} params 
   * 默认设计稿宽度为750px
   */
  set = (params={}) => {
    const {width} = params; // {width: 设计稿宽度}
    const font_size = (100 / (width ?? 750)) * 100; // 字体大小 = 100 / 设计稿宽度 (设计稿宽度/100rem为100%屏幕宽度, 例如:设计稿750px, 则7.5rem为100%屏幕)
    document.documentElement.style.fontSize = `${font_size}vw`;
  }
  /**
   * 移除自适应
   * @param {*} params 
   * 默认font-size为 14px
   */
  remove = (params={}) => {
    const {font_size} = params; 
    document.documentElement.style.fontSize = `${font_size??14}px`;

  }
}

const rem = new Rem();
export default rem;