/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-06-06 10:10:33
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-06 11:03:33
 * @FilePath     : /miaohang/src/utils/self_adaption/index.js
 * @Description  : 
 */
import platform from "./platform"
import rem from "./rem"
class SelfAdaption{
  set = (params) => {
    const {width, font_size} = params ?? {}
    const mobile = platform.isMobile()

    if(mobile){
      rem.set({width: width ?? 750})
    }else{
      rem.remove({font_size})
    }
    return {mobile}
  }
}

const self_adaption = new SelfAdaption()

export default self_adaption